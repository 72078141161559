import React from "react";
import { PageHeader } from "../../components/Layout";
import { Modal, message as notify, Button } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { MyTable, RowAction } from "../../components";
import { bankAccount as bankAccountApi } from "../../apis";
import { useSelector } from "react-redux";
import Form from "./Form";
import styled from "styled-components";

export default () => {
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState([]);
  const { isMobile } = useSelector(state => state.general);
  const [filters] = React.useState({
    query: "",
  });

  const columns = useHeader({
    onAction: async (key, record) => {
      switch (key) {
        case "edit" : {
          setAction(["update", record]);
          break;
        }
        case "remove" : {
          Modal.confirm({
            title     : "Баталгаажуулах",
            icon      : <ExclamationCircleOutlined />,
            content   : "Та үүнийг устгахдаа итгэлтэй байна уу!!!",
            okText    : "Устгах",
            cancelText: "Болих",
            className : "btn-custom-class",
            onOk      : async () => {
              try {
                await bankAccountApi.remove(record._id);
                myTableRef.current.reload();
              } catch (error) {
                console.log(error); }
            },
          });
          break;
        }
        default:
      }
    },
  });

  const onCancel = () => {
    setAction([]);
  };

  const onSubmit = async (data) => {
    if (action && action[0] === "update") {
      await bankAccountApi.update(action[1]._id, data);
    } else {
      await bankAccountApi.create(data);
    }

    notify.success("Таны хүсэлт амжилттай!");
    myTableRef.current.reload();
    onCancel();
  };

  return (
    <Container>
      <PageHeader
        title="Дансны мэдээлэл"
        extra={[
          <Button type="primary" onClick={() => setAction(["create"])}><PlusOutlined /> Нэмэх</Button>
        ]}
      />

      <MyTable
        bordered={!isMobile}
        columns={columns}
        ref={myTableRef}
        filters={filters}
        loadData={bankAccountApi.list}
        rowKey={(record) => record.id} />

      <Modal
        title={action && action[0] === "create" ? "Данс нэмэх" : "Данс засах"}
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} action={action} onSubmit={onSubmit} />
      </Modal>
    </Container>
  );
};
const Container = styled.div`
  margin-top: 40px;
  .ant-page-header {
    padding: 0 0 24px 0;
  }
  .ant-page-header-heading-title {
    font-size: 17px;
  }
`;
const useHeader = ({ onAction }) => {
  return [{
    title : "№",
    width : 10,
    render: (r, i, index) => {
      return index + 1;
    }
  }, {
    title : "Банк",
    render: (record) => {
      return record.bankName?.toUpperCase();
    }
  }, {
    title : "Дансны дугаар",
    render: (record) => {
      return <b>{record.bankAccountNumber}</b>;
    }
  }, {
    title : "Дансны нэр",
    render: (record) => {
      return record.bankAccountName?.toUpperCase();
    }
  }, {
    title : "Үйлдэл",
    width : 100,
    render: (record) => {
      return (
        <RowAction
          actions={{
            edit  : "Засварлах",
            remove: "Устгах",
          }}
          onClick={(key) => onAction(key, record)}
        />
      );
    }
  }];
};