import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import {
  PageContainer,
  PageHeader,
  PageContent,
} from "../../components/Layout";
import { CameraOutlined, QrcodeOutlined } from "@ant-design/icons";
import Transaction from "../Transaction";
import QrCodeForm from "./QrCodeForm";
import ScanForm from "./ScanForm";
import useFetch from "../../hooks/useFetch";
import PaymentForm from "./PaymentForm";
import { bankAccount as bankAccountApi, card as cardApi } from "../../apis";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default () => {
  const transactionRef = React.useRef();
  const dispatch = useDispatch();
  const { payment, action } = useSelector((state) => state.general);
  const [bankAccounts] = useFetch(bankAccountApi.select)({ rows: [] });
  const [cards] = useFetch(cardApi.select)({ rows: [] });

  // const [action, setAction] = React.useState([]);

  const onQrc = () => {
    dispatch({
      type   : "general.payment",
      payload: null,
      action : ["qrc", bankAccounts[0]],
    });

    // setAction(["qrc", bankAccounts[0]]);
  };

  const onAction = (key, row) => {
    dispatch({
      type   : "general.payment",
      payload: null,
    });

    switch (key) {
      case "pay": {
        onPayment(row);
        break;
      }
      default:
    }
  };

  const onScan = () => {
    dispatch({
      type   : "general.payment",
      payload: null,
      action : ["scan"],
    });

    // setAction(["scan"]);
  };

  const onCancel = (reload) => {
    if (reload) transactionRef.current.reload();
    dispatch({
      type  : "general.payment",
      action: [],
    });
    // setAction([]);
  };

  const onPayment = (data) => {
    console.log("called ?");

    dispatch({
      type   : "general.payment",
      payload: payment,
      action : ["payment", data],
    });

    // setAction(["payment", data]);

    transactionRef.current.reload();
  };

  // useEffect(() => {
  //   console.log("action", action)
  // }, [action])

  React.useEffect(() => {
    if (payment) onPayment(payment);

    console.log("payment", payment?.qrCode);
  }, [payment]);

  return (
    <PageContainer
      style={{ background: "#f7f7f7", height: "calc(100vh - 60px)" }}
    >
      <PageHeader
        title="Гүйлгээний мэдээлэл"
        extra={[
          <Button
            type="primary"
            shape="circle"
            onClick={() => onQrc()}
            icon={<QrcodeOutlined />}
          />,
          <Button
            type="primary"
            shape="circle"
            onClick={() => onScan()}
            icon={<CameraOutlined />}
          />,
        ]}
      />
      <PageContent>
        <Transaction ref={transactionRef} onAction={onAction} />
      </PageContent>

      {action && action[0] === "qrc" && (
        <Modal
          title="Wallet"
          visible={action && action[0] === "qrc"}
          onCancel={() => onCancel()}
          width={400}
          footer={false}
        >
          <QrCodeForm
            onCancel={onCancel}
            action={action}
            bankAccounts={bankAccounts}
            cards={cards}
            onPayment={onPayment}
          />
        </Modal>
      )}

      {action && action[0] === "scan" && (
        <Modal
          title="Scan QR"
          visible={true}
          onCancel={() => onCancel()}
          width={400}
          footer={false}
        >
          <ScanForm onCancel={onCancel} action={action} onPayment={onPayment} />
        </Modal>
      )}

      {action && action[0] === "payment" && (
        <Modal
          title="Payment"
          visible={true}
          onCancel={() => onCancel()}
          width={400}
          footer={false}
        >
          <PaymentForm
            onCancel={onCancel}
            bankAccounts={bankAccounts || []}
            cards={cards}
            action={action}
            qrCode={payment?.qrCode}
          />
        </Modal>
      )}
    </PageContainer>
  );
};
