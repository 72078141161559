import React from "react";
import * as Yup from "yup";
import { Button, PageHeader, message as notify } from "antd";
import { Formik } from "formik";
import { FormItem, Input, Form } from "formik-antd";
import { formItemLayout } from "../../utils";
import { simulator as simulatorApi } from "../../apis";
import styled from "styled-components";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const FormSchema = () => Yup.object({
  customerCode: Yup.string().required("Заавал бөглөнө!"),
  customerName: Yup.string().required("Заавал бөглөнө!"),
});

export default () => {
  const { simulator } = useSelector(state => state.general);
  const [data] = React.useState({
    customerName: undefined,
    customerCode: undefined,
    ...(simulator || {}),
  });

  const onSubmit = async (data) => {
    await simulatorApi.update(data);

    notify.success("Таны хүсэлт амжилттай!");
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema()}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form {...formItemLayout}>
              <PageHeader
                title="Холболтын мэдээлэл"
                extra={[
                  <Button htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
                ]}
              />
              <FormItem name="customerCode" label="Customer Code" required>
                <Input name="customerCode" placeholder="Customer Code" />
              </FormItem>
              <FormItem name="customerName" label="Customer Name" required>
                <Input name="customerName" placeholder="Customer Name" />
              </FormItem>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  .ant-page-header {
    padding: 0 0 24px 0;
  }
  .ant-page-header-heading-title {
    font-size: 17px;
  }
`;