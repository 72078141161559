import React from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

const FormSchema = () => Yup.object().shape({
  number    : Yup.string().required("Заавал бөглөнө!"),
  holderName: Yup.string().required("Заавал бөглөнө!"),
  cvv       : Yup.string().required("Заавал бөглөнө!"),
  expiryDate: Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action, onSubmit, onCancel }) => {
  const [data] = React.useState({
    number    : undefined,
    holderName: undefined,
    cvv       : undefined,
    expiryDate: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem name="number" label="Number" required>
            <Input name="number" placeholder="0000 0000 0000 0000" />
          </FormItem>
          <FormItem name="holderName" label="Holder name" required>
            <Input name="holderName" placeholder="Holder name" />
          </FormItem>
          <FormItem name="cvv" label="CVV" required>
            <Input name="cvv" placeholder="000" />
          </FormItem>
          <FormItem name="expiryDate" label="Expiry date" required>
            <Input name="expiryDate" placeholder="YYYY/MM" />
          </FormItem>
          <AntForm.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};

const StaffContainer = styled.div`
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  background: #fffad0;
  padding: 10px;
`;
