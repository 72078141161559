import React from "react";
import { Menu, Dropdown } from "antd";
import { NavLink, useHistory, } from "react-router-dom";
import { BarChartOutlined, SettingOutlined, DownOutlined, SendOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
// import { nsp as nspApi } from "../../apis";
import usePermission from "../../hooks/usePermission";
import styled from "styled-components";
import useFetch from "../../hooks/useFetch";

const { SubMenu } = Menu;

export default ({ isMobile }) => {
  const history = useHistory();
  const { nsp } = useSelector(state => state.general);
  // const [nsps] = useFetch(nspApi.select)([]);
  const { checkPermission } = usePermission();

  let selected = (history.location.pathname.split("/")[1] || "") + "-" + (history.location.pathname.split("/")[2] || "");

  const onChange = async ({ key }) => {
    // await nspApi.change(key);

    window.location.href = "/";
  };

  return (
    <Container>
      {nsp._id && (
        <div className={`nsp ${isMobile && "mobile"}`}>
          <Dropdown.Button overlay={() => {
            return (
              <Menu onClick={onChange}>
                {/* {nsps.map(item => (
                  <Menu.Item key={item._id}>{item.name}</Menu.Item>
                ))} */}
              </Menu>
            );
          }} placement="bottomCenter" icon={<DownOutlined />}>
            {nsp?.name}
          </Dropdown.Button>
        </div>
      )}

      <Menu
        defaultSelectedKeys={[selected]}
        mode="inline"
        theme="light"
        inlineCollapsed={isMobile}>

        {checkPermission(["MAINTAINER", "DEVELOPER", "REPORTER", "GUEST"]) && (
          <Menu.Item key="-" icon={<BarChartOutlined />}>
            Дашбоард <NavLink to="/" />
          </Menu.Item>
        )}

        {checkPermission(["MAINTAINER", "DEVELOPER"]) && (
          <Menu.Item key="sms-" icon={<SendOutlined />}>
            SMS <NavLink to="/sms" />
          </Menu.Item>
        )}

        {checkPermission(["MAINTAINER"]) && (
          <SubMenu key="settings" title="Тохиргоо" icon={<SettingOutlined />}>
            <Menu.Item key="settings-nsp">
              Namespace <NavLink to="/settings/nsp" />
            </Menu.Item>
            <Menu.Item key="settings-user">
              Хэрэглэгч <NavLink to="/settings/user" />
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </Container>
  );
};

const Container = styled.div`
  z-index: 1000;
  .nsp {
    padding: 20px;
    width: 100%;
    &.mobile {
      .ant-btn-group {
        .ant-btn:first-child {
          display: none!important;
        }
      }
    }
    .ant-btn-group {
      width: 100%;
      .ant-btn:last-child {
        width: 39px!important;
      }
      .ant-btn:first-child {
        width: 100%;
      }
    }
  }
  .ant-menu {
    border-right: none;

    .ant-menu-item {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      color: #333;
      font-weight: 400;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #1890ff;
      }
    }
  }
`;
