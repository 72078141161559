import React from "react";
import { Layout } from "antd";
import { Header } from "./components";
import { useDispatch } from "react-redux";
import { Simulator } from "./pages";
import styled from "styled-components";
import { general } from "./apis";
import SocketClient from "./components/SocketClient";

const App = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [collapse, setCollapse] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const asyncFn = async () => {
      try {
        await Promise.all([general.init()(dispatch)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    asyncFn();

  }, [dispatch]);

  React.useEffect(() => {
    dispatch({
      type   : "layout.mobile",
      payload: isMobile
    });

    if (isMobile)
      setCollapse(true);
  }, [isMobile]);

  if (loading) return <div>loading...</div>;

  return (
    <div>
      <Header isMobile={isMobile} setCollapse={setCollapse} collapse={collapse} />
      <Container collapse={collapse}>
        <Layout className="content">
          <div className="wrapper">
            <Simulator />
          </div>
        </Layout>
      </Container>

      <SocketClient />
    </div>
  );
};

export default App;

const Container = styled.div`
  overflow: hidden; 
  .content { 
    position: relative;
    width: 100%; 
    background: #fff;
    .navbar {
      display: flex;
      /* width: ${(props) => props.collapse ? 80 : 230}px; */
      border-right: 1px solid #e8e8e8;
      height: calc(100vh - 60px);
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      overflow: auto;
      overflow-x: hidden; 
      background: #ffffff;
      animation: translating 0.5s linear infinite;
      transition: 0.5s ease; 
       
    }
    .wrapper {
      position: relative; 
      /* padding-left: ${(props) => props.collapse ? 80 : 230}px; */
      animation: translating 0.5s linear infinite;
      transition: 0.5s ease;
      height: calc(100vh - 60px);
      overflow: auto;
      overflow-x: hidden; 
    }
  }
`;
