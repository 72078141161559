import request from "../utils/request";

export const get = (id, options) => request.get(`/api/card/${id}`, {}, options);
export const select = (data, options) => request.get("/api/card/select", data, options);
export const list = (data, options) => request.get("/api/card", data, options);
export const create = (data) => request.post("/api/card", data);
export const update = (id, data) => request.put(`/api/card/${id}`, data);
export const remove = (id) => request.del(`/api/card/${id}`);

export const qrc = (id) => request.get(`/api/card/${id}/qrc`);


