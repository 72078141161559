import React from "react";
import { Select, Tag, Radio, Button, message } from "antd";
import { payment as paymentApi } from "../../apis";
import { tugrug } from "../../utils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Buffer } from "buffer";

export default ({ bankAccounts, cards, action, onCancel, qrCode }) => {
  const [loading, setLoading] = React.useState(false);
  const [accountSelected, setAccountSelected] = React.useState(false);
  const [accountId, setAccountId] = React.useState(null);
  const { payment } = useSelector((state) => state.general);
  const [data, setFormData] = React.useState({
    ...(action && action[1] ? action[1] : {}),
    paymentMethod: "BANK_ACCOUNT",
    bankAccount  : undefined,
    card         : undefined,
  });

  const masked = (pan) => {
    return (
      pan.substring(0, 4) +
      " " +
      pan.substring(4, 6) +
      "** **** " +
      pan.substring(12, 16)
    );
  };

  const onChange = async (name, e) => {
    setFormData((state) => ({ ...state, [name]: e }));
  };

  const onPay = async () => {
    setLoading(true);

    try {
      await paymentApi.pay(data?._id, {
        method     : data.paymentMethod,
        bankAccount: data.bankAccount,
        card       : data.card,
      });

      onCancel();
    } catch (err) {
      message.error(err?.message);
    }
  };

  React.useEffect(() => {
    if (payment && payment.paymentStatus === "PAID") {
      setLoading(false);
      onCancel(true);

      message.success("Таны хүсэлт амжилттай!");
    }
  }, [payment]);

  React.useEffect(() => {
    console.log("qrCode", qrCode);
    if (qrCode !== undefined && qrCode !== "" && qrCode !== null) {
      const decodedQrCode = Buffer.from(qrCode, "base64").toString();
      // console.log("decodedQrCode", decodedQrCode);
      const accountId = decodedQrCode.substring(22, 22 + decodedQrCode.length);
      // console.log("accountId", accountId);
      if (accountId) {
        // console.log("bankAccounts", bankAccounts);

        let account = bankAccounts.find((obj) => obj._id === accountId);
        if (account) {
          setFormData((prevState) => ({
            ...prevState,
            bankAccount  : account._id,
            paymentMethod: "BANK_ACCOUNT",
          }));
          setAccountSelected(true);
        } else {
          let card = cards.find((obj) => obj._id === accountId);
          if (card){
            setFormData((prevState) => ({
              ...prevState,
              card         : card._id,
              paymentMethod: "CARD",
            }));
            setAccountSelected(true);
          }
        }
      }
    }
  }, []);

  // React.useEffect(() => {
  //   console.log("data=====>", data);
  // }, [data]);

  return (
    <Container>
      <div className="ant-table-container">
        <table style={{ width: "100%", marginBottom: 20 }}>
          <thead className="ant-table-thead">
            <tr>
              <th
                className="ant-table-cell"
                colSpan={2}
                style={{ textAlign: "left" }}
              >
                Нэхэмжлэл
              </th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            <tr>
              <td className="ant-table-cell">Төлбөр хүлээн авагч</td>
              <td className="ant-table-cell">
                <b>{data?.customerName}</b>
              </td>
            </tr>
          </tbody>
          <tbody className="ant-table-tbody">
            <tr>
              <td className="ant-table-cell">Төлбөрийн мэдээлэл</td>
              <td className="ant-table-cell">{data?.description}</td>
            </tr>
            <tr>
              <td className="ant-table-cell">Дүн</td>
              <td className="ant-table-cell">{tugrug(data?.amount || 0)}</td>
            </tr>
          </tbody>
          {data.beneficiaryBankAccounts?.map((account, index) => (
            <tbody className="ant-table-tbody" key={index}>
              <tr>
                <td className="ant-table-cell">Банкны нэр</td>
                <td className="ant-table-cell">{account?.bankCode}</td>
              </tr>
              <tr>
                <td className="ant-table-cell">Дансны дугаар</td>
                <td className="ant-table-cell">
                  <b>{account?.accountNumber}</b>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">Дансны нэр</td>
                <td className="ant-table-cell">{account?.accountName}</td>
              </tr>
            </tbody>
          ))}

          <tbody className="ant-table-tbody">
            <tr>
              <td className="ant-table-cell">Төлөв</td>
              <td className="ant-table-cell">
                {data.paymentStatus === "PAID" ? (
                  <Tag color="green">ТӨЛӨГДСӨН</Tag>
                ) : (
                  <Tag color="red">ТӨЛӨГДӨӨГҮЙ</Tag>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {data.paymentStatus !== "PAID" && (
        <div style={{ textAlign: "center" }}>
          <Radio.Group
            defaultValue={data.paymentMethod}
            buttonStyle="solid"
            onChange={(e) =>
              setFormData((state) => ({
                ...state,
                paymentMethod: e.target.value,
              }))
            }
            disabled={loading || accountSelected}
          >
            <Radio.Button value="CARD">Карт</Radio.Button>
            <Radio.Button value="BANK_ACCOUNT">Данс</Radio.Button>
          </Radio.Group>

          {data.paymentMethod === "BANK_ACCOUNT" ? (
            <Select
              value={data?.bankAccount}
              placeholder="Сонгох"
              style={{ width: "100%", marginTop: 20 }}
              onChange={(e) => onChange("bankAccount", e)}
              disabled={loading || accountSelected}
              allowClear
            >
              {bankAccounts?.map((item, index) => (
                <Select.Option key={index} value={item._id}>
                  <Tag>
                    {item.bankName}: {item.bankAccountNumber}
                  </Tag>
                  {item.bankAccountName}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select
              value={data?.card}
              placeholder="Сонгох"
              style={{ width: "100%", marginTop: 20 }}
              onChange={(e) => onChange("card", e)}
              disabled={loading || accountSelected}
              allowClear
            >
              {cards?.map((item, index) => (
                <Select.Option key={index} value={item._id}>
                  <Tag>VISA: {masked(item.number)}</Tag> {item.holderName}
                </Select.Option>
              ))}
            </Select>
          )}

          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={() => onPay()}
            loading={loading}
            disabled={(() => {
              if (data.paymentMethod === "BANK_ACCOUNT" && !data.bankAccount)
                return true;

              if (data.paymentMethod === "CARD" && !data.card) return true;

              return false;
            })()}
            block
          >
            Төлөх
          </Button>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  .qrc-payload {
    padding: 40px;
  }
  .ant-table-thead > tr > th {
    padding: 4px;
  }
  .ant-table-tbody > tr > td {
    padding: 4px;
  }
`;
