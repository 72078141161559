import React from "react";
import { Input, Button, message } from "antd";
import { CameraOutlined, LoadingOutlined } from "@ant-design/icons";
import { qrc as qrcApi } from "../../apis";
import styled from "styled-components";
import { useSelector } from "react-redux";

export default ({ onPayment }) => {
  const [payload, setPayload] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { payment } = useSelector((state) => state.general);

  const onChange = async (value) => {
    setPayload(value);
    setLoading(true);

    try {
      await qrcApi.decrypt({
        payload: value,
      });
    } catch (err) {
      console.log(err);
      message.error("Invalid QR Code!");
      setLoading(false);
      setPayload("");
    }
  };

  React.useEffect(() => {
    console.log("payment bn ", payment);
    if (payment) onPayment(payment);
  }, [payment]);

  return (
    <Container>
      <Button type="primary">
        {loading ? <LoadingOutlined /> : <CameraOutlined />}
      </Button>
      <div className="qrc-payload">
        <Input.TextArea
          placeholder="QRC Payload"
          value={payload}
          rows="6"
          onChange={(e) => onChange(e.target.value)}
          disabled={loading}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  .qrc-payload {
    padding: 40px;
  }
`;
