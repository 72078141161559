import React from "react";
import { Select, Tag, Radio, Input, Button, message } from "antd";
import QRCode from "react-qr-code";
import styled from "styled-components";
import { card as cardApi, bankAccount as bankAccountApi } from "../../apis";
import copy from "copy-to-clipboard";

export default ({ bankAccounts, cards }) => {
  const [payload, setPayload] = React.useState();
  const [mode, setMode] = React.useState("bank_card");
  const [data, setFormData] = React.useState({
    bankAccount: undefined,
    card       : undefined,
  });

  const masked = pan => {
    return pan.substring(0, 4) +
    " " +
    pan.substring(4, 6) +
    "** **** " +
    pan.substring(12, 16);
  };

  const onChange = async (e) => {
    let res;

    if (mode === "bank_card") {
      setFormData({
        card: e
      });

      res = await cardApi.qrc(e);
    } else {
      setFormData({
        bankAccount: e
      });

      res = await bankAccountApi.qrc(e);
    }

    setPayload(res.payload);
  };

  const onCopy = () => {
    copy(payload);

    message.success("Copied!");
  };

  return (
    <Container>
      <Radio.Group defaultValue={mode} buttonStyle="solid" onChange={e => setMode(e.target.value)}>
        <Radio.Button value="bank_card">Карт</Radio.Button>
        <Radio.Button value="bank_account">Данс</Radio.Button>
      </Radio.Group>

      {mode === "bank_account" ? (
        <Select value={data?.bankAccount} placeholder="Сонгох" style={{ width: "100%", marginTop: 20 }} onChange={onChange} allowClear>
          {bankAccounts?.map((item, index) => (
            <Select.Option key={index} value={item._id}>
              <Tag>{item.bankName}: {item.bankAccountNumber}</Tag>
              {item.bankAccountName}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Select value={data?.card} placeholder="Сонгох" style={{ width: "100%", marginTop: 20 }} onChange={onChange} allowClear>
          {cards?.map((item, index) => (
            <Select.Option key={index} value={item._id}>
              <Tag>VISA: {masked(item.number)}</Tag> {item.holderName}
            </Select.Option>
          ))}
        </Select>
      )}

      {payload && (
        <>
          <div className="qrc-payload">
            <QRCode value={payload} />
          </div>
          <Input placeholder="QRC Payload" value={payload} suffix={<Button size="small" type="default" onClick={() => onCopy()}>Copy</Button>} disabled />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  .qrc-payload {
    padding: 40px;
  }
`;