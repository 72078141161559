/* eslint-disable no-nested-ternary */
import React from "react";
import { Tag, Button, Input, DatePicker } from "antd";
import { payment as paymentApi } from "../../apis";
import { CustomTable, RowAction } from "../../components";
import { SearchOutlined } from "@ant-design/icons";
import { Filter } from "../../components/Design";
import { tugrug } from "../../utils";
import styled from "styled-components";
import moment from "moment";

export default React.forwardRef(({ onAction }, ref) => {
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    query     : "",
    start_date: "",
    end_date  : "",
  });

  React.useEffect(() => {
    let timeout;

    if (timeout) clearTimeout(timeout);
    if (filters.query !== query)
      timeout = setTimeout(() => {
        setFilters((state) => ({ ...state, query: query }));
      }, 300);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [query]);

  const dateFilter = (e) => {
    setFilters({
      ...filters,
      start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"),
      end_date  : e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm"),
    });
  };

  const onClear = () => {
    setQuery("");
    setFilters({
      query    : "",
      startDate: "",
      endDate  : "",
    });
  };

  React.useImperativeHandle(ref, () => ({
    reload() {
      myTableRef.current.reload();
    },
  }));

  return (
    <>
      <Filter
        extra={[
          <DatePicker.RangePicker value={[filters.start_date && moment(filters.start_date), filters.end_date && moment(filters.end_date)]} onChange={dateFilter} />,
          <Button onClick={onClear}>Цэвэрлэх</Button>,
        ]}>
        <Input className='grid-item' prefix={<SearchOutlined />} value={query} style={{ width: "250px" }} placeholder='Хайлт хийх...' onChange={(e) => setQuery(e.target.value)} allowClear />
      </Filter>

      <TransactionTable
        size='small'
        ref={myTableRef}
        filters={filters}
        loadData={paymentApi.list}
        rowKey={(record) => record.id}
        thead={() => (
          <thead className='ant-table-thead'>
            <tr>
              <th className='ant-table-cell' rowSpan={2}>
                №
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Дансны мэдээлэл
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Гүйлгээний утга
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Гүйлгээний дүн
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Төлөв
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Огноо
              </th>
              <th className='ant-table-cell' rowSpan={2}>
                Үйлдэл
              </th>
            </tr>
          </thead>
        )}
        tbody={(row, index) => {
          return (
            <tbody key={index} className='ant-table-tbody'>
              <tr>
                <td className='ant-table-cell' rowSpan={2}>
                  {index + 1}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {row.beneficiaryBankAccounts?.map((account, index) => (
                    <Tag color={account.isDefault && "blue"} key={index}>
                      {account.bankCode}: {account.accountNumber}
                      <div>{account.accountName}</div>
                    </Tag>
                  ))}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  <div>{row.description}</div>
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {tugrug(row.amount)}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {row.paymentStatus === "PAID" ? <Tag color='green'>ТӨЛӨГДСӨН</Tag> : row.paymentStatus === "REFUNDED" ? <Tag color='yellow'>БУЦААГДСАН</Tag> : <Tag>ХҮЛЭЭГДЭЖ БАЙНА</Tag>}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  {moment(row.paymentStatusDate).format("YYYY.MM.DD HH:mm")}
                </td>
                <td className='ant-table-cell' rowSpan={2}>
                  <RowAction
                    actions={{
                      pay     : row.paymentStatus !== "PAID" ? "Төлбөр төлөх" : undefined,
                      reversal: "Буцаалт хийх",
                    }}
                    onClick={(key) => onAction(key, row)}
                  />
                </td>
              </tr>
            </tbody>
          );
        }}
      />
    </>
  );
});

const TransactionTable = styled(CustomTable)`
  .ant-table-thead > tr > th {
    padding: 8px;
    line-height: 15px;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
`;
