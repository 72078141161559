import React from "react";
import { Form, FormItem, Input, Select } from "formik-antd";
import { Button, Form as AntForm } from "antd";
import { formItemLayout, tailFormItemLayout } from "../../utils";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const FormSchema = () => Yup.object().shape({
  bankCode         : Yup.string().required("Заавал бөглөнө!"),
  bankAccountName  : Yup.string().required("Заавал бөглөнө!"),
  bankAccountNumber: Yup.string().required("Заавал бөглөнө!"),
});

export default ({ action, onSubmit, onCancel }) => {
  const { banks } = useSelector(state => state.general);
  const [data] = React.useState({
    bankCode         : undefined,
    bankAccountName  : undefined,
    bankAccountNumber: undefined,
    ...(action && action[0] === "update" ? action[1]: {})
  });

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={FormSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form {...formItemLayout}>
          <FormItem name="bankCode" label="Банк" required>
            <Select name="bankCode" placeholder="Сонгох" allowClear>
              {banks?.map((item, index) => (
                <Select.Option key={index} value={item.code}>{item.name}</Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="bankAccountName" label="Дансны нэр" required>
            <Input name="bankAccountName" placeholder="Дансны нэр"/>
          </FormItem>
          <FormItem name="bankAccountNumber" label="Дансны дугаар" required>
            <Input name="bankAccountNumber" placeholder="Дансны дугаар"/>
          </FormItem>
          <AntForm.Item {...tailFormItemLayout}>
            <Button className="button" style={{ marginRight: 10 }} htmlType="submit" type="primary" loading={isSubmitting}>Хадгалах</Button>
            <Button className="button" type="default" onClick={onCancel}>Болих</Button>
          </AntForm.Item>
        </Form>
      )}
    </Formik>
  );
};