import { message } from "antd";

const initialState = {
  isMobile : false,
  simulator: null,
  banks    : [],
  payment  : null,
  action   : [],
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const { simulator, banks } = action.payload;

      return {
        ...state,
        banks,
        simulator,
      };
    }
    case "general.payment": {
      return {
        ...state,
        payment: action.payload,
        action : action.action,
      };
    }
    case "general.not_found": {
      message.error("INVOICE NOT FOUND");
      return {
        ...state,
        payment: null,
        action : [],
      };
    }
    case "general.already_paid": {
      message.error("ALREADY PAID");
      return {
        ...state,
        payment: null,
        action : [],
      };
    }
    default:
      return state;
  }
};

export default general;
