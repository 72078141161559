import React from "react";
import { Row, Col } from "antd";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import Wallet from "../Wallet";
import Form from "./Form";
import AccountList from "../Account/List";
import CardList from "../Card/List";

export default () => {
  return (
    <Row gutter={8}>
      <Col span={8}>
        <PageContainer>
          <PageHeader title="Тохиргоо" />
          <PageContent>
            <Form />
            <AccountList />
            <CardList />
          </PageContent>
        </PageContainer>
      </Col>
      <Col span={16}>
        <Wallet />
      </Col>
    </Row>
  );
};